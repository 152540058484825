<template>
  <div>
    <v-breadcrumbs
      :items="pathModul"
      divider="-"
      normal
      class="pathModuls"
    ></v-breadcrumbs>

    <v-container class="container-filtering">
      <v-row align="center">
        <!-- Column Select Station -->
        <v-col class="col-input-filter">
          <v-select
            v-on:change="selectStation"
            :items="itemStation"
            item-value="station_id"
            item-text="name"
            label="Station"
          ></v-select>
        </v-col>

        <!-- Column Select Calendar -->
        <v-col class="col-input-filter">
          <v-menu
            v-model="modalCalendar"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="valueFromDateep"
                label="From Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="valueFromDateep"
              v-on:change="selectFromDate"
              @input="modalCalendar = false"
            >
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-col>

        <!-- Column Select Calendar2 -->
        <v-col class="col-input-filter">
          <v-menu
            v-model="modalCalendar2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="valueToDateep"
                label="To Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="valueToDateep"
              v-on:change="selectToDate"
              @input="modalCalendar2 = false"
            >
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-col>

        <!-- Column Generate Button -->
        <v-col cols="12" sm="6" md="4" class="col-btn-generateReport">
          <v-btn
            class="ma-2 white--text btn-generate primary"
            solo
            dense
            normal
            @click="getExceedanceParam"
          >
            Generate Report
            <v-icon right dark big>
              mdi-table-arrow-up
            </v-icon>
          </v-btn>

          <v-btn color="green" elevation="2" @click="exportReportExceedParam">
            <v-icon color="white" center dark>
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-subheader
        class="subTitles fontSubTitle-blue"
        v-if="queryValueep == true"
      >
        <!-- EXCEEDANCE BY PARAMETER ALERT RECORD FOR {{ valueStationep }} ({{ valueFromDateep }} TO {{ valueToDateep }}) -->
        Exceedance By Parameter Alert Record For {{ valueStationep }} ({{
          this.changeDateFormat(valueFromDateep)
        }}
        To {{ this.changeDateFormat(valueToDateep) }})
      </v-subheader>

      <v-data-table
        :headers="thead_alertexceedanceByParameter"
        :items="tbody_alertexceedanceByParameter"
        :items-per-page="5"
        class="elevation-1 headerDtSarawak"
        :custom-sort="customSort"
        :loading="loadingTable"
      >
        <!-- <template v-slot:header="{ props: { headers } }">
            <thead>
                <tr>
                    <th v-for="(h,key) in headers" 
                    :key="key"
                    divider='true'
                    class="table-header">
                    <span>{{h.text}}</span>
                    </th>
                </tr>
            </thead>
        </template> -->

        <template v-slot:[`item.notes`]="props">
          <v-edit-dialog
            :return-value.sync="props.item.notes"
            large
            persistent
            @save="saveExceedParam"
            @cancel="cancelExceedParam"
            @open="openExceedParam"
            @close="closeExceedParam"
          >
            <div>{{ props.item.notes }}</div>
            <template v-slot:input>
              <div class="mt-4 text-h6">
                Update Notes
              </div>
              <v-text-field
                v-model="props.item.notes"
                label="Edit"
                single-line
                counter
                autofocus
                @input="
                  updateNotesExceedParam = {
                    newId: props.item.newId,
                    notes: $event,
                  }
                "
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
      </v-data-table>

      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ snackText }}

        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" text @click="snack = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
// import XLSX from 'xlsx';
import Papa from "papaparse";

export default {
  data: () => ({
    pathModul: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Alert - Exceedance By Parameter Alert Record",
        disabled: true,
        href: "/Alert/ExceedanceByParameter",
      },
    ],
    itemStation: [],
    // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    // date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    modalCalendar: false,
    menu2: false,
    modalCalendar2: false,

    loadingTable: false,
    thead_alertexceedanceByParameter: [
      {
        text: "STATION ID",
        value: "stationId",
        divider: true,
        align: "center",
        sortable: false,
      },
      { text: "DATETIME", value: "datetime", divider: true, align: "center" },
      {
        text: "EXCEEDANCE",
        value: "paramSelected",
        divider: true,
        align: "center",
      },
      {
        text: "NOTES",
        value: "notes",
        divider: true,
        align: "center",
        sortable: false,
      },
      {
        text: "UPDATED BY",
        value: "notesBy",
        divider: true,
        align: "center",
        sortable: false,
      },
      {
        text: "UPDATED ON",
        value: "notesUpdatedDt",
        divider: true,
        align: "center",
      },
    ],
    tbody_alertexceedanceByParameter: [],

    valueStationep: null,
    valueFromDateep: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    valueToDateep: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    queryValueep: false,

    snack: false,
    snackColor: "",
    snackText: "",
    pagination: {},
    updateNotesExceedParam: {
      newId: "",
      notes: "",
    },
  }),

  methods: {
    selectStation(stationId) {
      // console.log(stationId);
      this.valueStationep = stationId;
    },

    selectFromDate(fromDate) {
      // console.log(fromDate);
      this.valueFromDateep = fromDate;
    },

    selectToDate(toDate) {
      // console.log(toDate);
      this.valueToDateep = toDate;
    },

    changeDateFormat(date) {
      var dd = date.split("-")[2];
      var mm = date.split("-")[1];
      var yyyy = date.split("-")[0];
      date = dd + "/" + mm + "/" + yyyy;
      return date;
    },

    changeDatetimeFormat(datetime) {
      if (datetime != "" && datetime != null) {
        let [date, time] = datetime.split("T");

        //convert date
        var dd = date.split("-")[2];
        var mm = date.split("-")[1];
        var yyyy = date.split("-")[0];
        date = dd + "/" + mm + "/" + yyyy;

        // Convert Time to 12 hours
        var H = +time.substr(0, 2);
        var h = H % 12 || 12;
        var ampm = H < 12 ? "AM" : "PM";
        time = h + time.substr(2, 3) + time.substr(5, 3) + ampm;

        datetime = date + ", " + time;
        return datetime;
      } else {
        return (datetime = null);
      }
    },

    getStationExceedanceParam() {
      axios
        .get("https://apis.spatialworks.com.my/sarawak/cms/stations", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          let stationInfo = [];
          // let stationInfo = [];

          response.data.forEach(function(res) {
            let objStation = {};
            objStation["station_id"] = res.stationId;
            objStation["name"] = res.stationId + " - " + res.location;
            stationInfo.push(objStation);
          });

          this.itemStation = stationInfo;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getExceedanceParam() {
      this.loadingTable = true;
      axios
        .get(
          "https://apis.spatialworks.com.my/sarawak/cms/exceedance?station=" +
            this.valueStationep +
            "&startdt=" +
            this.valueFromDateep +
            "&enddt=" +
            this.valueToDateep,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {
          let displayData = [];
          response.data.forEach(function(res) {
            var match = res.paramSelected.split("|");
            var categories = res.category;
            if (match.length == 1 && categories == "PARAM") {
              displayData.push(res);
            }
            // if(match.length == 1){
            //     displayData.push(res);
            // }
          });

          this.tbody_alertexceedanceByParameter = displayData;
          for (let i = 0; i < displayData.length; i++) {
            displayData[i].datetime = this.changeDatetimeFormat(
              displayData[i].datetime
            );
            displayData[i].notesUpdatedDt = this.changeDatetimeFormat(
              displayData[i].notesUpdatedDt
            );
          }

          //   this.tbody_alertexceedanceByParameter = response.data;
          //   for (let i=0; i < response.data.length; i++){
          //     response.data[i].datetime = this.changeDatetimeFormat(response.data[i].datetime);
          //     response.data[i].notesUpdatedDt = this.changeDatetimeFormat(response.data[i].notesUpdatedDt);
          //   }
          this.queryValueep = true;
          this.loadingTable = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingTable = false;
        });
    },

    // open modal notes
    saveExceedParam() {
      const that = this;
      axios
        .put(
          `https://apis.spatialworks.com.my/sarawak/cms/exceedance`,
          this.updateNotesExceedParam,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          that.getExceedanceParam();
        })
        .catch((error) => {
          console.log(error);
        });
      console.log(this.updateNotesExceedParam);
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    },
    cancelExceedParam() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    openExceedParam() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    closeExceedParam() {
      console.log("Dialog closed");
    },

    exportReportExceedParam() {
      // const data = XLSX.utils.json_to_sheet(this.tbody_alertexceedanceByParameter);
      // const wb = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(wb, data, 'data');
      // XLSX.writeFile(wb,'Exceedance By Parameter Alert Record For ' + this.valueStationep + ' (' + this.changeDateFormat(this.valueFromDateep) + ' TO ' + this.changeDateFormat(this.valueToDateep) + ').xlsx');
      // console.log(this.tbody_alertexceedanceByParameter);
      let headerExceedParam = [];
      for (let i = 0; i < this.thead_alertexceedanceByParameter.length; i++) {
        headerExceedParam.push(this.thead_alertexceedanceByParameter[i].value);
      }

      let keysExceedParam = headerExceedParam,
        result = this.tbody_alertexceedanceByParameter.map((o) =>
          Object.assign(...keysExceedParam.map((k) => ({ [k]: o[k] })))
        );

      console.log(result);

      var blob = new Blob([Papa.unparse(result)], {
        type: "text/csv;charset=utf-8;",
      });

      var link = document.createElement("a");

      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        "Exceedance By Parameter Alert Record For " +
          this.valueStationep +
          " (" +
          this.changeDateFormat(this.valueFromDateep) +
          " TO " +
          this.changeDateFormat(this.valueToDateep) +
          ").csv"
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    customSort: function(items, index, isDesc) {
      //   console.log(items, index, isDesc);

    //   console.log(index);

      items.sort((a, b) => {
        if (index[0] == "datetime" || index[0] == "notesUpdatedDt") {
          //   console.log(new Date(this.standardDtFormatNuzul(b[index])));
          if (!isDesc[0]) {
            return (
              new Date(this.standardDtFormatNuzul(b[index])) -
              new Date(this.standardDtFormatNuzul(a[index]))
            );
          } else {
            return (
              new Date(this.standardDtFormatNuzul(a[index])) -
              new Date(this.standardDtFormatNuzul(b[index]))
            );
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
  },

  mounted() {
    this.getStationExceedanceParam();
  },
};
</script>

<style lang="scss">
@import "~scss/main";

.pathModuls {
  padding-bottom: 0px;
}

.pathModuls > li:nth-child(3) > a {
  color: black !important;
}

.container-filtering {
  padding: 0px;
}

.col-input-filter {
  padding: 0px 10px;
}

.col-btn-generateReport {
  margin-bottom: 18px;
}

.btn-generate {
  margin: 0px !important;
  // background: #4495D1 !important;
}

.container-filtering::v-deep .v-text-field.v-text-field--solo .v-input__control,
.btn-generate .v-btn .v-btn--contained {
  min-height: 10px;
  margin-top: 10px;
}

.subTitles {
  margin-left: 28%;
  // color: #1976D2 !important;
}

// .table-header {
//     white-space: nowrap;
//     padding: 16px !important;
//     // background: #4495D1;
//     // color: white !important;
//     text-align: center !important;
//     border: 1px solid rgba(0, 0, 0, 0.12);
// }
</style>